import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Modal} from 'react-bootstrap';

const LoginForm = ({ onSubmit }) => {

    return (
        <div className="_button-wrapper _full_width">

            <p className="text-center">
                Dapatkan Bonus Spesial Ini, Jika Daftar VIP Waiting List Sebelum 30 Mei 2021
                - 3 Video Series dan Disc Up To 60% Early Bird Commitment Mastery
            </p>

            <Form method="POST" action="https://mrjose.activehosted.com/proc.php">
            <input type="hidden" name="u" value="71" />
            <input type="hidden" name="f" value="71" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Nama Lengkap : </Form.Label>
                <Form.Control type="text" name="fullname" placeholder="Wajib" required/>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email Aktif:</Form.Label>
                <Form.Control type="email" name="email" placeholder="Wajib" required/>
            </Form.Group>
            <Button className="__color-outline btn-success" type="submit" size="lg" block >
                Submit
            </Button>
            </Form>

        </div>
    );
};

export default function Secnine(){
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const onLoginFormSubmit = (e) => {
      e.preventDefault();
      handleClose();
    };
    return (
        <>
            <Container fluid className="__bg-secondary-miror-plc-v2">
                <Container>
                    <Row className="fade-in">
                        <Col lg={6} md={12} sm={12} xs={12} className="order-lg-last order-md-last">
                            <div className="__image_happy_woman_plc d-flex justify-content-center">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/Ladingpage-plc/couple-image%20sec-6-B.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="__text-desc mt-3">
                                <h2 className="__text-headline text-white">
                                    Lantas, bagaimana cara agar pria mau komit dengan wanitanya?
                                </h2>
                                <p className="text-left text-white">
                                    Fortunately, di hadapanmu sekarang ada sebuah solusi yang bisa bikin pria sukarela komit sama kamu dengan cara yang elegan dan tepat tanpa bikin hubungan jadi bubar.
                                </p>
                                <p className="text-left text-white">
                                    Dan bisa kamu praktekkan agar pria mau komit sama kamu tanpa paksaan, tanpa pertengkaran.
                                </p>
                                <p className="text-left text-white">
                                    Sementara kamu masih menunda join training ini, maka kamu sedang menunda <b>dalam mendapatkan komitmen dari pria yang kamu inginkan!</b>
                                </p>
                                <p className="text-left text-white">
                                    <b>So, sebelum menyesal Klik Join Waiting List sekarang! </b>
                                </p>
                                <div className="__flex-mobile">
                                <Button variant="primary" size="lg" onClick={handleShow}>
                                    JOIN WAITING LIST
                                </Button>{' '}
                                </div>
                            </div>
                        </Col>
                        
                    </Row>
                </Container>
            </Container>
            <Modal 
            
            show={show} onHide={handleClose} 
            size="md"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="__text-wl-title">
                        Join Waiting List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="__modal-body-bg">
                    <LoginForm onSubmit={onLoginFormSubmit} />
                </Modal.Body>
                
            </Modal>
        </>
    )
}